export default {
  methods: {
    updateActions(pagelink) {
      let userId = this.currentUser?.user?.id;
      let actionJson = {
        pagelink: pagelink,
      };
      if (userId) {
        actionJson.userId = userId;
        actionJson.sessionId = this.currentUser.sessionId;
      }

      // Use this.$axios to make the post request
      this.$axios.post("action-update", actionJson).then((response) => {
        // Handle response if needed
      });
    },
  },
};
